// import { div } from "gatsby"
import React from "react"
import Plus from "../images/plus.svg"
import Minus from "../images/minus.svg"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';




const PlusSign = () => <><img style={{ width: 20 }} src={Plus} alt='expand' /></>
const MinusSign = () => <><img className="d-none" style={{ width: 20 }} src={Minus} alt='collapse' /></>

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, (e) => {
        e.target.previousSibling.classList.toggle('d-none');
        e.target.nextSibling.classList.toggle('d-none');
    }
    );

    return (
        <div
            style={{ position: 'absolute', width: '100%', height: "100%" }}
            onClick={decoratedOnClick}
        >
            {children}
        </div>
    );
}

const FAQ = () => {
    return (
        <section className="section-theming bg-white" style={{ paddingBottom: '40px' }} id="faq">
            <div className="container p-4">
                <h2 className="is-purple p-4 header-allignment">Frequently Asked Questions</h2>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">What is Ketamine?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4"> Ketamine is an FDA approved medication that was synthesized in 1962
                                    and was approved for use as an anesthetic medication in 1970. It is
                                    a blocker of the cellular NMDA receptor, a neural receptor for glutamate
                                    that has proved to play a significant role in major depression, chronic
                                    pain syndromes, fibromyalgia and other ailments. It is also known to have
                                    potent anti-inflammatory properties and is known to stimulate neuronal growth,
                                    synaptogenesis, and neuroplasticity. It is a Schedule III drug and should only
                                    be used and administered by a skilled licensed anesthesiologist, nurse anesthetist,
                                    or trained physician.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">Is Ketamine safe?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4"> Although often known as a drug of abuse i.e. “Special K”,
                                ketamine is very safe drug when administered for anesthesia or as a treatment for depression
                                and chronic pain as we do at Chicago Ketamine Centers. The doses used for depression are much
                                lower than those used for anesthesia and patients are monitored closely for the duration of
                                the 45-minute infusion. Most patients feel very relaxed and free while they are receiving the
                                infusion and these effects wear off within an hour or so afterwards. We carefully assess your
                                medical history and medication list before the treatment and will consult your primary care
                                physician or mental health professional as needed.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">What are the side effects of Ketamine?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4">  The side effects of the low dose ketamine infusions in our protocols
                                are minimal and will only last about an hour or two, but may include mild drowsiness, nausea, and
                                increased blood pressure. We will routinely administer medication for prevention of nausea. Less
                                common side effects include vivid dreams or agitation and these can be controlled by adjusting dosage.
                                Many patients don’t mind the relaxed and free feeling they experience during the infusion and this
                                feeling has been postulated to be beneficial.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">Are Ketamine infusions addictive?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4"> No, there is no evidence that ketamine infusion at the low
                                doses and frequency used to treat mood disorders leads to dependence or addiction.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">Will Ketamine help my depression?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4">  Multiple randomized clinical studies over the past 15 years
                                have shown that ketamine infusions can effectively treat major depression and treatment resistant
                                depression (TRD) with a success rate as high as 80%. Most patients have immediate improvement after
                                their first or second infusion. All patients will differ in response to the treatment and will also
                                differ in their need for maintenance infusions. Patients with suicidal or harmful thoughts are particular
                                high responders to ketamine infusions with over 80% having complete elimination of these thoughts after a
                                single infusion.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">How does Ketamine compare to conventional antidepressant medications?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4"> Ketamine will produce relief of depression in up to 80% of those patients that are treatment
                                resistant. Oral antidepressant medications may only be effective in about 60% of patients and come with many undesirable side
                                effects such as weight gain, decreased sexual drive, emotional and cognitive dulling, increased suicidal ideation, GI disturbances,
                                and fatigue. There are only minimal side effects with ketamine and these are gone within a few hours of the infusion. Ketamine
                                is also effective within minutes to hours whereas oral antidepressants can take up to a month or longer for effect. Potentially,
                                with the guidance of your physician or mental health professional, you may be able to decrease or even eliminate your other
                                medications as your treatment progresses, hence ridding you of those negative side effects.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold"> What other things does Ketamine treat?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4">  In addition to depression, Ketamine has also been clinically proven to treat chronic neuropathic
                                pain such as CRPS(RSD), fibromyalgia, OCD, and PTSD. The infusion protocols for these other conditions may differ from those for
                                depression. Please inquire directly.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">How many infusions will I need?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4">  Our protocol for depression is to administer six ketamine infusions over a course of 2 weeks and
                                then maintenance infusions as needed every month to every few months after. Each infusion will take less than one hour. Even though
                                you should have no residual effects on discharge, we insist that you do not drive home. For chronic pain treatment, infusions are
                                multiple and can each last up to 4 hours.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className="pt-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="is-purple m-0 font-weight-bold">Does insurance pay for the ketamine infusions?</h3>
                                <PlusSign />
                                <CustomToggle as={Card.Header} eventKey="0" />
                                <MinusSign />
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="is-purple pt-4">  No, at this time your insurance will not pay for the ketamine infusions.
                                We accept most major credit cards and cash. We also offer third-party patient financing programs.
                                Please call us for protocol pricing.
                            </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </section>
    );
}

export default FAQ